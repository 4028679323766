<template>
  <div>
    <p
      style="
        text-align: center;
        font-size: 50px;
        color: #0c2231;
        font-weight: bold;
      "
    >
      {{
        bot_id
          ? 'Step 2 Update Chatbot ' +
            chatbot_name +
            ': ' +
            (botType === 'file' ? 'Upload File' : 'Set Input')
          : 'Step 2: ' + (botType === 'file' ? 'Upload File' : 'Set Input')
      }}
    </p>
    <div class="row justify-content-center">
      <div class="col-md-6">
        <CCard>
          <CCardHeader>Chatbot Details</CCardHeader>
          <CCardBody>
            <div class="form-group">
              <label for="bot-name-input">Chatbot Name:</label>
              <input
                id="bot-name-input"
                type="text"
                v-model="botName"
                class="form-control"
              />
            </div>
          </CCardBody>
        </CCard>
        <CCard>
          <CCardHeader>
            {{ botType === 'file' ? 'File Upload' : 'Input' }}
          </CCardHeader>
          <CCardBody>
            <div class="form-group">
              <template v-if="botType === 'file' || botType === 'audio'">
                <label for="file-upload"><Strong>Select Files:</Strong></label>
                <input
                  id="file-upload"
                  type="file"
                  @change="handleFileUpload"
                  class="form-control-file"
                  :accept="selectFileTypes"
                  multiple
                />
                <div class="chatbot-step2__upload-area mt-3">
                  <div v-if="!selectedFiles && botType === 'file'">
                    <p>
                      Put your files here or Select File to Upload. Supported
                      File types:
                    </p>
                    <div class="two-column-list">
                      <ul>
                        <li>Word (.docx)</li>
                        <li>Powerpoint (.pptx)</li>
                        <li>Excel (.xlsx)</li>
                        <li>HTML (.html)</li>
                        <li>JPG (.jpg, .jpeg)</li>
                        <li>Emails (.msg)</li>
                      </ul>
                      <ul>
                        <li>Text (.txt)</li>
                        <li>CSV (.csv)</li>
                        <li>PDF (.pdf)</li>
                        <li>Markdown (.md)</li>
                        <li>Images (.png, .svg)</li>
                      </ul>
                    </div>
                  </div>
                  <div v-if="!selectedFiles && botType === 'audio'">
                    <p>
                      Put your files here or Select File to Upload. Supported
                      File types:
                    </p>
                    <div class="two-column-list">
                      <ul>
                        <li>M4A - Apple Lossless Audio Codec (.m4a)</li>
                        <li>MP3 - MPEG Audio Layer III (.mp3)</li>
                        <li>WEBM - WebM Audiovisual Media (.webm)</li>
                        <li>MP4 - MPEG-4 Part 14 (.mp4)</li>
                        <li>MPGA - MPEG Audio Stream (.mpga)</li>
                        <li>WAV - Waveform Audio File Format (.wav)</li>
                        <li>MPEG - Moving Picture Experts Group (.mpeg)</li>
                        <li>MOV - QuickTime File Format (.mov)</li>
                        <li>FLAC - Free Lossless Audio Codec (.flac)</li>
                      </ul>
                    </div>
                  </div>

                  <div
                    style="text-align: left"
                    v-if="selectedFiles && selectedFiles.length > 0"
                  >
                    <Strong>Selected files:</Strong>
                    <ul>
                      <li v-for="file in selectedFiles" :key="file.name">
                        {{ file.name }}
                      </li>
                    </ul>
                  </div>
                </div>
              </template>
              <br />
              <div v-if="botType === 'audio'"><Strong>OR</Strong></div>
              <template v-if="botType === 'web' || botType === 'audio'">
                <label for="url-input">{{
                  botType === 'web' ? 'Enter URL:' : 'Enter Youtube URL:'
                }}</label>
                <input
                  id="url-input"
                  type="text"
                  v-model="url"
                  class="form-control"
                  @input="clearSelectedFiles"
                />
              </template>
            </div>
          </CCardBody>
        </CCard>
        <div class="text-center mt-4">
          <button
            @click="openModal"
            class="btn btn-info white-text"
            :disabled="shouldDisableButton"
          >
            <template v-if="isCreatingChatbot"> Creating Chatbot ... </template>
            <template v-else>
              {{ this.bot_id ? 'Update Chatbot' : 'Create Chatbot' }}
            </template>
          </button>
          <div class="loading-icon-wrapper">
            <template v-if="isCreatingChatbot">
              <img
                src="../../public/images/loading.gif"
                alt="Creating Chatbot..."
                class="loading-icon"
              />
            </template>
          </div>
        </div>

        <div v-if="showConfirmation" class="text-center mt-4">
          <p>Chatbot will be processed.</p>
          <button @click="routeToDashboard" class="btn btn-info white-text">
            OK
          </button>
        </div>
      </div>
      <!-- Add the modal markup -->
      <div v-if="showModal" class="modal">
        <div class="modal-content modal-half-width">
          <h2>Select URLs</h2>
          <div v-if="urls.length === 0">
            <p v-html="modalmessage"></p>
          </div>
          <div v-else class="modal-scrollable">
            <div class="table-container">
              <table class="url-table">
                <thead>
                  <tr>
                    <th>Select</th>
                    <th>URL</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style="width: 10%">
                      <input
                        type="checkbox"
                        id="select-all"
                        v-model="selectAll"
                        @change="toggleSelectAll"
                      />
                    </td>
                    <td>
                      <label for="select-all"
                        ><strong>Select All</strong></label
                      >
                    </td>
                  </tr>
                  <tr v-for="url in urls" :key="url">
                    <td style="width: 10%">
                      <input
                        type="checkbox"
                        :id="url"
                        v-model="selectedUrls"
                        :value="url"
                      />
                    </td>
                    <td>{{ url }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="modal-buttons">
            <button @click="closeModal" class="btn btn-info white-text">
              Cancel
            </button>
            <button
              @click="createChatbot"
              :disabled="selectedUrls.length === 0"
              class="btn btn-info white-text"
            >
              OK
            </button>
          </div>
        </div>
      </div>
      <!-- End of modal markup -->
      <div v-if="showErrorPopup" class="popup">
        <div class="popup-content">
          <p>{{ errorMessage }}</p>
          <div class="popup-buttons">
            <button
              v-if="planerror"
              @click="navigateToPricingPage"
              class="btn btn-info white-text"
            >
              Manage Plan
            </button>
            <button @click="cancel" class="btn btn-info white-text">
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import HTTPService from '../services/HTTPService'

export default {
  name: 'ChatbotStep2',
  props: {
    botType: {
      type: String,
      required: true,
    },
    bot_id: {
      type: String,
      required: false,
    },
    chatbot_name: {
      type: String,
      required: false,
    },
  },
  computed: {
    shouldDisableButton() {
      return (
        this.isCreatingChatbot ||
        this.botName === '' ||
        (this.url === '' && !this.selectedFiles)
      )
    },
    selectFileTypes() {
      if (this.botType == 'file') {
        return '.docx,.pptx,.xlsx,.html,.jpg, .jpeg, .csv, .txt, .pdf, .md, .png, .svg, .msg'
      }
      if (this.botType == 'audio') {
        return '.m4a, .mp3, .webm, .mp4, .mpga, .wav, .mpeg, .flac, .mov'
      }
      return ''
    },
  },
  data() {
    return {
      selectedFiles: null,
      botName: '',
      url: '',
      showConfirmation: false,
      isCreatingChatbot: false,
      errorMessage: '',
      showErrorPopup: false,
      showModal: false,
      urls: [],
      selectedUrls: [],
      modalmessage:
        'Retrieving URLs...<br>Depending on how large the site is, this may take a while so please be patient!',
      planerror: false,
      selectAll: false,
    }
  },
  created() {
    //console.log(this.botType === 'file')
    //console.log(this.chatbot_name)
    if (this.chatbot_name) {
      this.botName = this.chatbot_name
    }
  },
  methods: {
    handleFileUpload(event) {
      const files = event.target.files
      const maxSize = 25 * 1024 * 1024 // 25MB in bytes

      for (let i = 0; i < files.length; i++) {
        const file = files[i]

        if (file.size > maxSize) {
          alert(`File '${file.name}' exceeds the limit of 25MB.`)
          // Clear the input value to allow selecting a new file
          event.target.value = ''
          return
        }
      }

      this.selectedFiles = event.target.files
      // Use the selected files as needed
      console.log(this.selectedFiles)
    },
    openModal() {
      if (this.botType === 'web') {
        this.urls = []
        this.selectedUrls = []
        this.showModal = true
        this.modalmessage =
          'Retrieving URLs...<br>Depending on how large the site is, this may take a while so please be patient!'
        this.getUrls()
      } else {
        this.createChatbot()
      }
    },
    getUrls() {
      // get the url from the text input

      HTTPService.get_url(this.url)
        .then((response) => {
          //console.log(response.data)
          if (!response.data.urls || response.data.urls.length === 0) {
            this.modalmessage =
              'Failed to retrieve URLs, please check the url "' +
              this.url +
              '" is correct and try again'
          } else {
            this.urls = response.data.urls.sort()
          }
        })
        .catch((error) => {
          console.error(error)
          this.modalmessage =
            'Failed to retrieve URLs, please check the url "' +
            this.url +
            '" is correct and try again'
        })
    },
    closeModal() {
      this.showModal = false
    },
    createChatbot() {
      this.showModal = false
      const formData = new FormData()
      if (this.botType === 'file' && this.selectedFiles) {
        for (let i = 0; i < this.selectedFiles.length; i++) {
          formData.append('files[]', this.selectedFiles[i])
        }
      }
      if (this.botType === 'web') {
        formData.append('url', JSON.stringify(this.selectedUrls))
      }
      if (this.botType === 'audio') {
        if (this.url.length > 0) {
          formData.append('url', JSON.stringify([this.url]))
        }
        if (this.selectedFiles) {
          for (let i = 0; i < this.selectedFiles.length; i++) {
            formData.append('files[]', this.selectedFiles[i])
          }
        }
      }
      if (this.botName) {
        formData.append('data', JSON.stringify({ bot_name: this.botName }))
      }
      if (this.bot_id) {
        formData.append('bot_id', this.bot_id)
      }
      this.isCreatingChatbot = true

      HTTPService.createChatbot(formData)
        .then((response) => {
          //console.log(response.data)
          this.showConfirmation = true
          let bot_id = response.data.bot_id
          this.routeToDashboard(bot_id)
        })
        .catch((error) => {
          console.error(error)
          if (error.response && error.response.data.error) {
            this.errorMessage = error.response.data.error
            this.showErrorPopup = true
            if (
              error.response &&
              (error.response.status === 423 || error.response.status === 424)
            ) {
              this.planerror = true
            }
          } else {
            this.errorMessage = 'Failed to create chatbot: ' + error.response
            this.showErrorPopup = true
          }
        })
        .finally(() => {
          this.isCreatingChatbot = false
        })
    },
    routeToDashboard(bot_id) {
      this.$emit('change-active-component', {
        component: 'Chatbot',
        params: { bot_id: bot_id },
      })
    },
    navigateToPricingPage() {
      this.$emit('change-active-component', {
        component: 'Pricing',
      })
    },
    cancel() {
      this.showErrorPopup = false
    },
    toggleSelectAll() {
      if (this.selectAll) {
        // Select all URLs
        this.selectedUrls = this.urls.slice()
      } else {
        // Deselect all URLs
        this.selectedUrls = []
      }
    },
  },
}
</script>

<style scoped>
.chatbot-step2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.chatbot-step2__form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.chatbot-step2__label {
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

.chatbot-step2__input {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
}

.chatbot-step2__input input {
  width: 80%;
  padding: 0.5rem;
  font-size: 1.2rem;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.chatbot-step2__file-upload {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.chatbot-step2__upload-area {
  margin-top: 1rem;
  border: 1px dashed #ccc;
  padding: 1rem;
  text-align: center;
  cursor: pointer;
  border-radius: 5px;
}

.chatbot-step2__create-button {
  margin-top: 2rem;
  font-size: 1.2rem;
  padding: 1rem 2rem;
  border-radius: 5px;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
}

.c-dropzone {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 200px;
  border: 2px dashed #ccc;
  border-radius: 5px;
  transition: border-color 0.3s ease;
}

.c-dropzone__text {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  line-height: 1.5;
  color: #ccc;
}

.c-dropzone:hover {
  border-color: #0c2231;
}

.c-dropzone--dragging {
  border-color: #0c2231;
}

.loading-icon {
  display: inline-block;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  vertical-align: middle;
  background-color: transparent;
}

.loading-icon-wrapper {
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  width: 400px;
}

.modal-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.modal-half-width {
  width: 50%; /* Adjust the width as needed */
}

.modal-scrollable {
  max-height: 300px; /* Adjust the maximum height as needed */
  overflow-y: auto;
}

.table-container {
  margin-top: 10px; /* Adjust the margin as needed */
}

.url-table {
  width: 100%;
  border-collapse: collapse;
}

.url-table th,
.url-table td {
  padding: 8px;
  border: 1px solid #ccc;
}

.url-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.popup-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  width: 400px; /* adjust the width as needed */
}

.popup-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.two-column-list {
  display: flex;
  justify-content: center;
}

.two-column-list ul {
  list-style-type: disc; /* Add this line to display bullet points */
  padding-left: 0;
  text-align: left; /* Center the text within each list item */
}

.two-column-list ul:first-child {
  margin-right: 100px; /* Add right margin to create padding between columns */
}

.white-text {
  color: white;
}
</style>
